import './../App.css';
import desk from './../assets/images/desk.jpg';
import ellipse from './../assets/uinel-assets/images/heroes/elipse.png';
import blue1 from './../assets/uinel-assets/elements/heroes/lines-blue1.svg';
import blue2 from './../assets/uinel-assets/elements/heroes/lines-blue2.svg';
import {Helmet} from "react-helmet";
import favicon from './../assets/images/favicon.ico';
import {Link} from "react-router-dom";
import Header from "../components/Header";

function Home() {
    return (
        <div>
            <Helmet>
                <link rel="preconnect" href="https://fonts.gstatic.com" />
                <link href="https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap" rel="stylesheet" />
                <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap" rel="stylesheet" />
                <link rel="shortcut icon" href={favicon} type="image/x-icon" />
                <link rel="icon" href={favicon} type="image/x-icon" />
            </Helmet>
            <div>
                <Header />
                <section className="pb-16 2xl:pb-20 overflow-hidden">
                    <div className="relative bg-blueGray-100 rounded-b-10xl">
                        <div className="container px-4 mx-auto">
                            <div className="flex flex-wrap items-center -mx-4 pt-16 pb-20">
                                <div className="w-full lg:w-1/2 px-4 mb-24 lg:mb-0">
                                    <span
                                        className="block mb-9 font-medium tracking-widest uppercase text-sm text-gray-300">GOOD COMMUNICATION AND QUALITY WORK</span>
                                    <h1 className="max-w-xl mb-16 font-heading font-medium text-9xl md:text-10xl xl:text-13xl leading-none">
                                        <span>A custom made solution for </span>
                                        <span className="relative inline-block">
                      <span className="absolute inset-0 xl:-left-8 xl:-right-8">
                        <img className="absolute w-full max-w-non inset-2"
                             src={blue1} alt=""/><img
                          className="absolute mt-1 lg:mt-2 xl:ml-4 w-full max-w-none inset-3"
                          src={blue2} alt=""/></span>
                      <span className="relative z-10">you</span>
                    </span>
                                    </h1>
                                    <p className="mb-16 lg:mb-24 text-xl text-darkBlueGray-400 leading-snug">Software
                                        development with a personal touch.</p>
                                    <div className="flex flex-col sm:flex-row">
                                        <Link to="/contact">
                                            <button
                                                className="py-5 px-10 mt-3 sm:mt-0 sm:-ml-4 font-heading font-medium tracking-tighter text-xl text-white text-center bg-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 hover:bg-blue-600 rounded-xl">Get
                                                started
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="w-full lg:w-1/2 px-4">
                                    <div className="relative max-w-sm xl:max-w-none mx-auto">
                                        <img className="relative z-10 rounded-5xl" src={desk} alt=""/><img
                                        className="absolute -top-3 -left-24" src={ellipse}
                                        alt=""/></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <section className="font-medium rounded-b-10xl py-24 2xl:pt-52 2xl:pb-40">
                <div className="container px-4 mx-auto">
                    <div className="grid md:grid-cols-2 lg:grid-cols-4 justify-center gap-16 xl:gap-40">
                        <div className="max-w-sm mx-auto text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor"
                                 className="w-18 h-18 mb-10 mx-auto" viewBox="0 0 16 16">
                                <path
                                    d="M3 6.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 3a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5z"/>
                                <path
                                    d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm12 1a1 1 0 0 1 1 1v1H1V2a1 1 0 0 1 1-1h12zm1 3v10a1 1 0 0 1-1 1h-2V4h3zm-4 0v11H2a1 1 0 0 1-1-1V4h10z"/>
                            </svg>
                            <h3 className="mb-10 font-heading text-5xl leading-loose">Websites</h3>
                            <p className="font-normal leading-loose text-darkBlueGray-400">Landing
                                page<br/>Portfolio<br/>Contact<br/>...
                            </p>
                        </div>
                        <div className="max-w-sm mx-auto text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor"
                                 className="w-18 h-18 mb-10 mx-auto" viewBox="0 0 16 16">
                                <path
                                    d="M2.5 4a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm2-.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm1 .5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"/>
                                <path
                                    d="M2 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H2zm12 1a1 1 0 0 1 1 1v2H1V3a1 1 0 0 1 1-1h12zM1 13V6h4v8H2a1 1 0 0 1-1-1zm5 1V6h9v7a1 1 0 0 1-1 1H6z"/>
                            </svg>
                            <h3 className="mb-10 font-heading text-5xl leading-loose">Webapplications</h3>
                            <p className="font-normal leading-loose text-darkBlueGray-400">Register/Login<br/>E-commerce<br/>Custom
                                functionality<br/>...</p>
                        </div>
                        <div className="max-w-sm mx-auto text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor"
                                 className="w-18 h-18 mb-10 mx-auto" viewBox="0 0 16 16">
                                <path
                                    d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z"/>
                                <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                            </svg>
                            <h3 className="mb-10 font-heading text-5xl leading-loose">Mobile apps</h3>
                            <p className="font-normal leading-loose text-darkBlueGray-400">Cross platform apps for easy
                                access</p>
                        </div>
                        <div className="max-w-sm mx-auto text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor"
                                 className="w-18 h-18 mb-10 mx-auto" viewBox="0 0 16 16">
                                <path
                                    d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                                <path
                                    d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9.06 9.06 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.437 10.437 0 0 1-.524 2.318l-.003.011a10.722 10.722 0 0 1-.244.637c-.079.186.074.394.273.362a21.673 21.673 0 0 0 .693-.125zm.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6c0 3.193-3.004 6-7 6a8.06 8.06 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a10.97 10.97 0 0 0 .398-2z"/>
                            </svg>
                            <h3 className="mb-10 font-heading text-5xl leading-loose">Support</h3>
                            <p className="font-normal leading-loose text-darkBlueGray-400">Change requests always
                                welcome</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Home;

import logo from './../assets/images/Black-logo-no-background.png';
import {Link} from "react-router-dom";
import {useEffect} from "react";

function Header(){
    useEffect(() => {
        setBurgerMenuBehaviour();
    })

    return (
        <div className="text-gray-700 font-heading font-medium relative bg-gray-50 bg-opacity-50">
            <nav className="flex justify-between px-6 lg:px-12 py-8">
                <div className="flex w-full items-center">
                    <Link to="/">
                        <img className="h-12" src={logo} alt=""/></Link>
                    <ul className="hidden xl:flex px-4 ml-32" style={{display: "none"}}>
                        <li className="mr-16"><a className="text-gray-400 hover:text-gray-500"
                                                 href="oops.html">About</a></li>
                        <li className="mr-16">
                            <a className="flex items-center text-gray-400 hover:text-gray-500" href="oops.html">
                                <span className="mr-4">Portfolio</span>
                                <svg width="8" height="5" viewBox="0 0 8 5" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M6.97291 0.193232C7.20854 -0.0644107 7.58938 -0.0644107 7.82328 0.193232C8.05804 0.450875 8.05978 0.867141 7.82328 1.12478L4.42529 4.80677C4.19053 5.06441 3.81056 5.06441 3.57406 4.80677L0.176073 1.12478C-0.0586909 0.868102 -0.0586909 0.450875 0.176073 0.193232C0.411706 -0.0644107 0.792544 -0.0644107 1.02644 0.193232L4.00098 3.21284L6.97291 0.193232Z"
                                        fill="currentColor"></path>
                                </svg>
                            </a>
                        </li>
                        <li><a className="text-gray-400 hover:text-gray-500" href="oops.html">Pricing</a></li>
                    </ul>
                    <div className="hidden xl:flex items-center ml-auto">
                        <a className="text-gray-400 hover:text-gray-500" href="oops.html">
                        </a>


                        <Link to="/contact">
                            <button
                                className="uppercase text-sm font-bold font-body border-2 border-gray-200 border-opacity-50 rounded-full py-3 px-5 tracking-wide hover:border-gray-300">
                                <span className="block mt-px">Contact</span>
                            </button>
                        </Link>
                    </div>
                </div>
                <button
                    className="navbar-burger self-center xl:hidden text-gray-400 hover:text-gray-500 focus:outline-none">
                    <svg width="25" height="16" viewBox="0 0 25 16" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <rect width="25" height="2" fill="currentColor"></rect>
                        <rect y="14" width="25" height="2" fill="currentColor"></rect>
                    </svg>
                </button>
            </nav>
            <div className="navbar-menu hidden fixed top-0 left-0 bottom-0 w-5/6 max-w-sm z-50">
                <div className="navbar-backdrop fixed inset-0 bg-gray-800 opacity-80"></div>
                <nav className="relative flex flex-col py-8 h-full w-full bg-white overflow-y-auto">
                    <div className="flex items-center pl-16 mb-8">
                        <Link className="text-2xl text-gray-800 font-bold" to="/">
                            <img className="h-12" src={logo} alt=""/></Link>
                    </div>
                    <div>
                        <ul style={{display: "none"}}>
                            <li className="mb-1 px-10"><a
                                className="block pl-8 py-4 text-body text-lg rounded-full hover:shadow-2xl"
                                href="oops.html">About</a></li>
                            <li className="mb-1 px-10">
                                <a className="flex items-center pl-8 py-4 text-body text-lg rounded-full hover:shadow-2xl"
                                   href="oops.html">
                                    <span>Portfolio</span>
                                    <svg className="ml-4" width="8" height="5" viewBox="0 0 8 5" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M6.97291 0.193232C7.20854 -0.0644107 7.58938 -0.0644107 7.82328 0.193232C8.05804 0.450875 8.05978 0.867141 7.82328 1.12478L4.42529 4.80677C4.19053 5.06441 3.81056 5.06441 3.57406 4.80677L0.176073 1.12478C-0.0586909 0.868102 -0.0586909 0.450875 0.176073 0.193232C0.411706 -0.0644107 0.792544 -0.0644107 1.02644 0.193232L4.00098 3.21284L6.97291 0.193232Z"
                                            fill="currentColor"></path>
                                    </svg>
                                </a>
                            </li>
                            <li className="mb-1 px-10"><a
                                className="block pl-8 py-4 text-body text-lg rounded-full hover:shadow-2xl"
                                href="oops.html">Pricing</a></li>
                        </ul>
                    </div>
                    <div className="mt-auto px-10">
                        <Link to="/contact">
                            <button
                                className="py-3 px-5 mt-6 w-full text-body font-body font-bold uppercase tracking-wide text-sm border-2 border-gray-200 hover:border-gray-300 border-opacity-50 rounded-full">
                                <span className="block mt-px">Contact</span></button>
                        </Link>
                        <p className="mt-6 mb-4 text-center">
                            <span className="text-sm text-darkBlueGray-400">2022 © Satori Solutions. All rights reserved.</span>
                        </p>
                    </div>
                </nav>
                <button className="navbar-close absolute top-5 p-6 right-5">
                    <div className="absolute top-3">
                        <span className="absolute w-px h-6 bg-black transform -rotate-45"></span>
                        <span className="absolute w-px h-6 bg-black transform rotate-45"></span>
                    </div>
                </button>
            </div>
        </div>
    )
}

function setBurgerMenuBehaviour() {
    // open
    const burger = document.querySelectorAll('.navbar-burger');
    const menu = document.querySelectorAll('.navbar-menu');

    if (burger.length && menu.length) {
        for (var i = 0; i < burger.length; i++) {
            burger[i].addEventListener('click', function() {
                for (var j = 0; j < menu.length; j++) {
                    menu[j].classList.toggle('hidden');
                }
            });
        }
    }

    // close
    const close = document.querySelectorAll('.navbar-close');
    const backdrop = document.querySelectorAll('.navbar-backdrop');

    if (close.length) {
        for (let i = 0; i < close.length; i++) {
            close[i].addEventListener('click', function() {
                for (let j = 0; j < menu.length; j++) {
                    menu[j].classList.toggle('hidden');
                }
            });
        }
    }

    if (backdrop.length) {
        for (let i = 0; i < backdrop.length; i++) {
            backdrop[i].addEventListener('click', function() {
                for (let j = 0; j < menu.length; j++) {
                    menu[j].classList.toggle('hidden');
                }
            });
        }
    }
}

export default Header;
import './App.css';
import 'react-notifications/lib/notifications.css';
// import {useEffect} from "react";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import {NotificationContainer} from "react-notifications";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Oops from "./pages/Oops";

function App() {
    // useEffect(event => {
    //     const onPageLoad = () => { setBurgerMenuBehaviour()};
    //     // Check if the page has already loaded
    //     if (document.readyState === "complete") {
    //         onPageLoad();
    //     } else {
    //         window.addEventListener("load", onPageLoad);
    //         // Remove the event listener when component unmounts
    //         return () => window.removeEventListener("load", onPageLoad);
    //     }
    // }, []);

    return (
        <div>
            <Router>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="*" element={<Oops />} />
                </Routes>
                <NotificationContainer />
            </Router>
        </div>
    );
}

// function setBurgerMenuBehaviour() {
//     // open
//     const burger = document.querySelectorAll('.navbar-burger');
//     const menu = document.querySelectorAll('.navbar-menu');
//
//     if (burger.length && menu.length) {
//         for (var i = 0; i < burger.length; i++) {
//             burger[i].addEventListener('click', function() {
//                 for (var j = 0; j < menu.length; j++) {
//                     menu[j].classList.toggle('hidden');
//                 }
//             });
//         }
//     }
//
//     // close
//     const close = document.querySelectorAll('.navbar-close');
//     const backdrop = document.querySelectorAll('.navbar-backdrop');
//
//     if (close.length) {
//         for (let i = 0; i < close.length; i++) {
//             close[i].addEventListener('click', function() {
//                 for (let j = 0; j < menu.length; j++) {
//                     menu[j].classList.toggle('hidden');
//                 }
//             });
//         }
//     }
//
//     if (backdrop.length) {
//         for (let i = 0; i < backdrop.length; i++) {
//             backdrop[i].addEventListener('click', function() {
//                 for (let j = 0; j < menu.length; j++) {
//                     menu[j].classList.toggle('hidden');
//                 }
//             });
//         }
//     }
// }

export default App;

import './../App.css';
import icon1 from './../assets/uinel-assets/images/contacts/icon1.svg';
import icon2 from './../assets/uinel-assets/images/contacts/icon2.svg';
import icon3 from './../assets/uinel-assets/images/contacts/icon3.svg';

import {Helmet} from "react-helmet";
import favicon from './../assets/images/favicon.ico';
import {NotificationManager} from "react-notifications";
import Header from "../components/Header";

function Home() {
    const showMailSent = () => {
        // NotificationManager.success('Your message has been successfully sent', 'Message sent');
        NotificationManager.error('This feature has not been implemented yet, sorry!', 'Mail not sent')
        console.log('test')
    }

    return (
        <div>
            <Helmet>
                <link rel="preconnect" href="https://fonts.gstatic.com" />
                <link href="https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap" rel="stylesheet" />
                <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap" rel="stylesheet" />
                <link rel="shortcut icon" href={favicon} type="image/x-icon" />
                <link rel="icon" href={favicon} type="image/x-icon" />
            </Helmet>
            <div>
                <Header />
</div>

    <section className="py-24 2xl:pt-28 2xl:pb-36 bg-blueGray-100 rounded-b-10xl"><div className="container px-4 mx-auto">
        <div className="flex flex-wrap -mx-4">
            <div className="w-full lg:w-1/2 px-4 mb-10 lg:mb-0">
                <div className="max-w-lg lg:mt-20 mx-auto">
                    <h2 className="text-9xl md:text-10xl xl:text-11xl font-medium font-heading mb-16 lg:mb-24 leading-tight">Contact us</h2>
                    <div className="max-w-sm py-4 border-b border-gray-200 border-opacity-50" style={{display: "none"}}>
                        <div className="flex items-center">
                            <img className="mr-6 h-14" src={icon1} alt="" /><a className="text-xl font-semibold" href="#">+ 32 492 ** ** **</a>
                        </div>
                    </div>
                    <div className="max-w-sm py-4 border-b border-gray-200 border-opacity-50">
                        <div className="flex items-center">
                            <img className="mr-6 h-14" src={icon2} alt="" /><a className="text-xl font-semibold" target="_blank" href="https://www.facebook.com/SatoriSolutionsEU/">fb messenger</a>
                        </div>
                    </div>
                    <div className="py-4">
                        <div className="flex items-center">
                            <img className="mr-6 h-14" src={icon3} alt="" /><a className="text-xl font-semibold" href="mailto:info@satorisolutions.eu">info@satorisolutions.eu</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full lg:w-1/2 px-4">
                <div className="max-w-xl mx-auto py-14 md:pt-16 md:pb-20 px-8 md:px-20 bg-white rounded-xl">
                    <h3 className="font-heading font-medium text-7xl lg:text-8xl mb-12">Message</h3>
                    <input className="mb-5 w-full px-12 py-5 text-xl border-2 border-blue-400 rounded-xl placeholder-darkBlueGray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50" type="email" placeholder="Your Email" /><textarea className="mb-10 w-full px-12 py-5 h-32 text-xl border-2 border-blue-400 rounded-xl placeholder-darkBlueGray-400 resize-none focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50" placeholder="Message" name="" id="" cols="30" rows="10"></textarea>
                        <div className="text-right">
                            <div className="inline-block py-5 px-10 w-full md:w-auto text-xl leading-6 text-white font-medium tracking-tighter font-heading text-center bg-blue-500 hover:bg-blue-600 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 rounded-xl" onClick={showMailSent}>Send</div>
                        </div>
                </div>
            </div>
        </div>
    </div>
    </section>
        </div>
    );
}

export default Home;

import './../App.css';
import frame from './../assets/uinel-assets/images/http-codes/frame.png';
import ellipseline from './../assets/uinel-assets/images/http-codes/elipse-line.svg';
import {Helmet} from "react-helmet";
import favicon from './../assets/images/favicon.ico';
import {Link} from "react-router-dom";
import Header from "../components/Header";

function Oops() {
    return (
        <div>
            <Helmet>
                <link rel="preconnect" href="https://fonts.gstatic.com" />
                <link href="https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap" rel="stylesheet" />
                <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap" rel="stylesheet" />
                <link rel="shortcut icon" href={favicon} type="image/x-icon" />
                <link rel="icon" href={favicon} type="image/x-icon" />
            </Helmet>
            <div>
                <Header />
                <section className="relative font-sans bg-white pt-20 lg:pb-20 xl:pb-0 overflow-hidden">
                    <img className="absolute bottom-0 md:top-0 right-0 h-96 md:h-full z-10 w-2/4 md:w-1/6 lg:w-auto"
                         src={frame} alt="" />
                        <img className="absolute bottom-0" src={ellipseline} alt="" />
                            <div className="relative container mx-auto px-4 z-10">
                                <div className="flex items-center flex-wrap -mx-4">
                                    <div className="w-full md:w-2/3 lg:w-1/2 px-4 mb-20 lg:mb-0">
                                        <span
                                            className="inline-block py-3 px-7 mb-10 text-xl font-medium font-heading leading-5 text-indigo-500 border border-indigo-500 rounded-6xl">Wooooooops!</span>
                                        <h2 className="font-medium text-9xl md:text-10xl xl:text-11xl font-heading mb-10 leading-tight max-w-xl">We
                                            can&rsquo;t find that page</h2>
                                        <p className="text-base leading-6 text-gray-400 mb-16 xl:mb-40">Or something has
                                            gone wrong.</p>
                                        <Link className="inline-block py-5 px-10 text-xl leading-6 text-white font-medium tracking-tighter font-heading bg-blue-500 hover:bg-blue-600 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 rounded-xl"
                                           to="/">Back home</Link>
                                    </div>
                                    <div className="w-full lg:w-1/2 px-4">
                                        <img className="rounded-6xl"
                                             src="https://media-private.canva.com/NCePI/MAEGfoNCePI/1/s3.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAJWF6QO3UH4PAAJ6Q%2F20211221%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20211221T184844Z&X-Amz-Expires=82455&X-Amz-Signature=4e603e38651416edc49c5f9f7a522e10dd0c21cfdb770d9d5a8a24b5ca39188c&X-Amz-SignedHeaders=host&response-expires=Wed%2C%2022%20Dec%202021%2017%3A42%3A59%20GMT"
                                             alt="" />
                                    </div>
                                </div>
                            </div>
                </section>
            </div>
        </div>
    );
}

export default Oops;
